import background from "./Fondo2.webp";
import saksa from "./5.jpg";
import banner1 from "./images/image14.png";
import banner1mobile from "./images/image15.png";
import home1 from "./banner.jpg";
import home2 from "./home4.png";
import logo_gris from "./logo.png";
import logo_white from "./logo_white.png";
const fondo =
  background;
const home4 =
home2;
const home5 =
home1;
  const banner = banner1;
  const banner2 = banner1mobile;

export { fondo, home4, home5, banner, banner2, logo_gris, logo_white };
