import logo from "./bercovich.png";
import logo2 from "./banco_azteca.png";
import logo3 from "./caja.png";
import logo4 from "./citromax.png";
import logo5 from "./construir.png";
import logo6 from "./dimater.png";
import logo7 from "./direcrentas.png";
import logo8 from "./ecosa.png";
import logo9 from "./edet.png";
import logo10 from "./ejesa.png";
import logo11 from "./elektra.png";
import logo12 from "./guapas.png";
import logo13 from "./noanet.png";
import logo14 from "./osppt.png";
import logo15 from "./pagofacil.png";
import logo16 from "./plasticosrioja.png";
import logo17 from "./produccionsa.png";
import logo18 from "./refinor.png";
import logo19 from "./virla.png";

export const logoClientes = [
    logo, logo2, logo3, logo4, logo5, logo6, logo7, logo8, logo9, logo10, logo11, logo12, logo13, logo14, logo15, logo16, logo17, logo18, logo19
]