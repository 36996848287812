import logo from  "./adicem.png";
import logo2 from  "./ampere.png";
import logo3 from  "./baslaing.png";
import logo4 from  "./bpsoluciones.png";
import logo5 from  "./corralondelpero.jpg";
import logo6 from  "./corralonelamigo.jpg";
import logo7 from  "./corralonacon.png";
import logo8 from  "./dimater.png";
import logo9 from  "./gomezhorm.png";
import logo10 from  "./gonella.png";
import logo11 from  "./lamadrid.png";
import logo12 from  "./pinturasaenz.jpg";
import logo13 from  "./voltaje.png";

export const logoRef = [
    logo, logo2, logo3, logo4, logo5, logo6, logo7, logo8, logo9, logo10, logo11, logo12, logo13, 
]